<template>
  <div class="">
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      @keydown="handleKeydownClick"
      ref="dialog"
      max-width="720px"
      content-class="example-evalued-dialog"
    >
      <div class="content-dialog-popup">
        <div class="title-popup">{{ $t("casbee.title_example_natural_energy_level") }}</div>
        <table class="table mt-5" cellspacing="0">
          <thead>
            <th class="table-head">{{ $t("casbee.header_table_evaluation_item") }}</th>
            <th class="table-head">{{ $t("casbee.header_table_evaluation_content") }}</th>
            <th class="table-head">{{ $t("casbee.header_table_recruitment_method") }}</th>
          </thead>
          <tbody class="table-body">
            <tr>
              <td class="description">{{ $t("casbee.content_use_of_daylight") }}</td>
              <td class="description">{{ $t("casbee.title_example_natural_energy_level") }}</td>
              <td class="description">
                <p class="br-mobile">{{ $t("casbee.content_light_shelf") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_top_light") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_high_side_light") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_others") }}</p>
              </td>
            </tr>
            <tr>
              <td class="description">{{ $t("casbee.content_use_of_ventilation") }}</td>
              <td class="description">
                {{ $t("casbee.content_natural_ventilation_system") }}
              </td>
              <td class="description">
                <p class="br-mobile">{{ $t("casbee.content_natural_damper") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_night_purge") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_ventilation_system_linked") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_ventilation_tower_solar") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_open_more_direction") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_others") }}</p>
              </td>
            </tr>
            <tr>
              <td class="description">{{ $t("casbee.content_geothermal_utilization") }}</td>
              <td class="description">
                {{ $t("casbee.content_geothermal_utilization_system") }}
              </td>
              <td class="description">
                <p class="br-mobile">{{ $t("casbee.content_cool_heat") }}</p>
                <p class="br-mobile">{{ $t("casbee.content_others") }}</p>
              </td>
            </tr>
            <tr>
              <td class="description">{{ $t("casbee.content_miscellaneous") }}</td>
              <td class="description">{{ $t("casbee.content_effective_system") }}</td>
              <td class="description"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-dialog>
  </div>
</template>
<script>
/** USE GUIDE:
 *  <button @click="dialog = !dialog">ExampleEvaluedDialog</button>
 *  <ExampleEvaluedDialog :dialog="dialog" @close="dialog = false"  />
 */
import { KEYS_CODE } from '@/constants/keyboard';
export default {
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    handleKeydownClick(event) {
      if (event.keyCode === KEYS_CODE.ESCAPE) {
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
    border-radius: 0px;
}

.content-dialog-popup {
  overflow: auto;
  padding: 20px;
  display: block;
  color: black;
  background-color: white;
  .title-popup {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  }
  .table {
    .table-head {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      background-color: $bgLight;
      padding: 8px 19px;
      white-space: nowrap;
      min-width: 120px;
      text-align: left;
      border-top: 1px solid $antiFlashWhite;
      &:not(:last-of-type) {
        border-right: 1px solid $antiFlashWhite;
      }
    }
    .table-body {
      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        padding: 8px 19px;
        border-bottom: 1px solid $antiFlashWhite;
        vertical-align: top;
        &:not(:last-of-type) {
          border-right: 1px solid $antiFlashWhite;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .content-dialog-popup {
    .table {
      .table-head {
        min-width: 85px;
      }
      .table-body {
        .description {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          padding: 8px 19px;
          border-bottom: 1px solid $antiFlashWhite;
          border-right: 1px solid $antiFlashWhite;
          &:first-of-type {
            border-left: 1px solid $antiFlashWhite;
          }
          &:last-of-type {
             padding: unset;
          }
          .br-mobile {
            padding: 8px 19px;
            margin: 0;
            &:not(:last-of-type) {
              border-bottom: 1px solid $antiFlashWhite;
            }
          }
        }
      }
    }
  }
}
</style>
