
import i18n from '@/lang/i18n';
import {PERPOSE_USING_BUILDING} from './outside';

export const FLOOR_AREA = {
    TYPE_1: i18n.t('casbee.option_less_than_300'),
    TYPE_2: i18n.t('casbee.option_between_300_and_2000'),
    TYPE_3: i18n.t('casbee.option_between_2000_and_10000'),
    TYPE_4: i18n.t('casbee.option_between_10000_and_30000'),
    TYPE_5: i18n.t('casbee.option_30000_or_more'),
}

export const MONITORING = {
    LEVEL_BLANK: i18n.t('casbee.option_blank'),
    LEVEL_4: i18n.t('casbee.option_level_4'),
    LEVEL_5: i18n.t('casbee.option_level_5')
}

export const NATURAL_ENERGY_LEVEL_IN_SCHOOL = {
    LEVEL_1: i18n.t('casbee.option_natural_energy_level_in_schooll_level1'),
    LEVEL_2: i18n.t('casbee.option_natural_energy_level_in_schooll_level2'),
    LEVEL_3: i18n.t('casbee.option_natural_energy_level_in_schooll_level3')
}

export const NATURAL_ENERGY_LEVEL_OUT_SCHOOL = {
    LEVEL_1: i18n.t('casbee.option_not_hired'),
    LEVEL_2: i18n.t('casbee.option_less_than_15_year'),
    LEVEL_3: i18n.t('casbee.option_more_than_15_year')
}
export const MONITORING_VALUE = [
    {
        type: i18n.t('casbee.option_blank'),
        value: 1
    },
    {
        type: i18n.t('casbee.option_level_4'),
        value: 0.975
    },
    {
        type: i18n.t('casbee.option_level_5'),
        value: 0.95
    }
]
export const NATURAL_ENERGY_LEVEL = [
    {
        type: i18n.t('casbee.option_natural_energy_level_in_schooll_level1'),
        value: 0
    },
    {
        type: i18n.t('casbee.option_natural_energy_level_in_schooll_level2'),
        value: 1
    },
    {
        type: i18n.t('casbee.option_natural_energy_level_in_schooll_level3'),
        value: 15
    },
    {
        type: i18n.t('casbee.option_not_hired'),
        value: 0
    },
    {
        type: i18n.t('casbee.option_less_than_15_year'),
        value: 1
    },
    {
        type: i18n.t('casbee.option_more_than_15_year'),
        value: 15
    }
]

export const ENERGY_CONSUMPTION = {
    [PERPOSE_USING_BUILDING.OFFICE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 1250
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 1250
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 1550
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 1850
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2150
        }
    ],
    [PERPOSE_USING_BUILDING.GOVERMNET_OFFICE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 1050
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 1050
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 1050
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 1050
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 1050
        }
    ],
    [PERPOSE_USING_BUILDING.RESEARCH_INSTITUTE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 2350 
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 2350 
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 2350 
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2350 
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2350 
        }
    ],
    [PERPOSE_USING_BUILDING.PRE_SCHOOL]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 540
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 540
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 540
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 540
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 540
        }
    ],
    [PERPOSE_USING_BUILDING.JUNIOR_HIGHT_SCHOOL_HOKKAIDO]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 510
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 510
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 510
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 510
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 510
        }
    ],
    [PERPOSE_USING_BUILDING.JUNIOR_HIGHT_SCHOOL_OTHER]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 320
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 320
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 320
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 320
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 320
        }
    ],
    [PERPOSE_USING_BUILDING.HIGHT_SCHOOL]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 360
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 360
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 360
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 360
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 360
        }
    ],
    [PERPOSE_USING_BUILDING.UNIVERSITY]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 860
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 860
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 860
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 860
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 1100
        }
    ],
    [PERPOSE_USING_BUILDING.SHOPPING_MALL]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 7250
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 7250
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 5000
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2950
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2950 
        }
    ],
    [PERPOSE_USING_BUILDING.GROCERY_STORE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 12900
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 12900
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 12900
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 12900
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 12900 
        }
    ],
   
    [PERPOSE_USING_BUILDING.HOME_ELECTRICAL_STORE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 2850
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 2850
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 2850
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2850
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2850
        }
    ],
    [PERPOSE_USING_BUILDING.OTHER_STORE]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 2300
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 2300
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 2300
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2300
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2300
        }
    ],
    [PERPOSE_USING_BUILDING.EATERY]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 19350
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 11950
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 3150
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 3150
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 3150
        }
    ],
    [PERPOSE_USING_BUILDING.THEATER]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 1350
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 1350
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 1350
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 1400
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 1400
        }
    ],
    [PERPOSE_USING_BUILDING.EXHIBITION_FACLITIES]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 1100
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 1100
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 1100
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 1100
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 1100
        }
    ],
    [PERPOSE_USING_BUILDING.STADIUM]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 1850
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 1850
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 1850
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 1850
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 1850
        }
    ],
    [PERPOSE_USING_BUILDING.FACTORY]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 500
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 500
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 500
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 500
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 500
        }
    ],
    [PERPOSE_USING_BUILDING.HOSPITAL]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value:  2200
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value:  2200
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 2200
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2450
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2950
        }
    ],
    [PERPOSE_USING_BUILDING.HOTEL]: [
        {
            type: i18n.t('casbee.option_less_than_300'),
            value: 2450
        },
        {
            type: i18n.t('casbee.option_between_300_and_2000'),
            value: 2450
        },
        {
            type: i18n.t('casbee.option_between_2000_and_10000'),
            value: 2450
        },
        {
            type: i18n.t('casbee.option_between_10000_and_30000'),
            value: 2750
        },
        {
            type: i18n.t('casbee.option_30000_or_more'),
            value: 2750
        }
    ]
}